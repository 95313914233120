import React from "react"
import SEO from "components/seo"
import Layout from "../components/layout"
import SubBanner from "../components/SubBanner"
import Testimonials from "../components/Testimonials"
import CTA from "../components/CTA"
import styled from "styled-components"
import tw from "tailwind.macro"
import { graphql } from "gatsby"

export const query = graphql`
  query PageDetail($id: String!) {
    page: allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        id
        acfContent {
          content
        }
        acfSubPageBanner {
          subPageBannerImage {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          sloganText: subPageBannerSloganText
          sloganTitle: subPageBannerSloganTitle
        }
        acfCta {
          ctaShow
          ctaText
          ctaLink1 {
            target
            title
            url
          }
          ctaLink2 {
            target
            title
            url
          }
        }
        acfTestimonials {
          testimonialShow
          testimonialCard {
            testimonialAuthorCompany
            testimonialAuthorName
            testimonialContent
            testimonialImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 325) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ...SEO
      }
    }
  }
`

const PageContentSection = styled.div`
  ul {
    li {
      ${tw`mb-10`}
    }
  }

  p {
    ${tw`mb-10`}
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`font-medium mb-10`}
  }
`

const PageContent = ({ location, data }) => {
  const {
    acfContent,
    acfSubPageBanner,
    acfCta,
    acfTestimonials,
    seo,
  } = data.page.nodes[0]

  return (
    <Layout path={location.pathname}>
      <SEO data={seo} />
      <SubBanner
        mainTitle={acfSubPageBanner.sloganTitle}
        subTitle={acfSubPageBanner.sloganText}
        image={
          acfSubPageBanner?.subPageBannerImage?.localFile?.childImageSharp
            ?.fluid
        }
      />
      <PageContentSection className="section-gap-sm xl:section-gap-xl">
        <div className="max-w-xl mx-auto px-18 md:px-40 lg:px-60 xxl:px-0">
          <div dangerouslySetInnerHTML={{ __html: acfContent.content }}></div>
        </div>
      </PageContentSection>

      {acfTestimonials.testimonialShow && (
        <Testimonials data={acfTestimonials.testimonialCard} />
      )}

      {acfCta.ctaShow && (
        <CTA
          text={acfCta.ctaText}
          buttonOne={acfCta.ctaLink1}
          buttonTwo={acfCta.ctaLink2}
        />
      )}
    </Layout>
  )
}

export default PageContent
